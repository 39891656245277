import promokuRouter from "src/features/PromoKu/pages/router";
import referralRouter from "src/features/Referral/pages/router";

import { IRouteProps } from "./type";

const router: IRouteProps[] = [
  {
    name: "Home Page",
    routerProps: {
      path: "/",
      element: <div>Home Page</div>,
    },
  },
  ...promokuRouter,
  ...referralRouter,
];

export default router;
